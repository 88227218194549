import React, { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import { CardMedia, Card, CardContent } from '@material-ui/core';
import { Link } from 'gatsby';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import './ResearchLandingTile.scss';

export default function ResearchLandingTile({ content, basePath, url }) {
  const [contentData,setContentData]=useState('')
  const urlPath = url == "" ? contentData?.researchSlug : url;
  useEffect(()=>{
    setContentData({...content})
  },[])
  return (
    <Grid item xl={4} lg={4} md={6} sm={6} xs={12} key={urlPath}>
      <div className="card-main-section">
        <Card className="research-card">
          <Link to={`/${basePath}/` + urlPath} className="research-card-link">
            <CardMedia
              component="img"
              alt={contentData?.thumbnailImage?.description}
              height="240"
              image={contentData?.thumbnailImage?.file?.url + '?fm=webp&q=100'}
            />
            <CardContent className="card-content">
              <Grid item container>
                <Grid item direction="column">
                  <div className="publishing-date">
                    {contentData?.resourceType} / {contentData?.publishingDate}
                  </div>
                  <div className="research-title">{contentData?.researchTitle}</div>
                </Grid>
              </Grid>
              <div className="description">
                {contentData?.shortDescription?.raw &&
                  documentToReactComponents(JSON.parse(contentData?.shortDescription?.raw))}
              </div>
            </CardContent>
          </Link>
        </Card>
      </div>
    </Grid>
  );
}
