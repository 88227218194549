import React from 'react';
import ResearchLandingHeader from './ResearchLandingHeader';
import './ResearchLanding.scss';
import { myStyles } from './styles';
import clsx from 'clsx';

export default function ResearchLanding({ landingData, contentData, multiResource, basePath, location }) {
  const classes1 = myStyles(landingData?.capsulesBackgroundImage);
  const classes2 = myStyles(landingData?.leftDonutBackgroundImage);
  const classes3 = myStyles(landingData?.dotMatrixBackgroundImage);
  
  return (
    <>
      <div className="research-landing-main">
        <div className={clsx('capsules-bg-right', classes1['bg-capsules'])}>
          <div
            className={clsx(
              'bg-donuts-research-landing',
              classes2['bg-donut'],
              classes3['bg-dot-matrix']
            )}
          >
            <ResearchLandingHeader
              landingData={landingData}
              contentData={contentData}
              multiResource={multiResource}
              basePath={basePath}
              location={location}
            />
          </div>
        </div>
      </div>
    </>
  );
}
